export default {
  aboutUs: '会社概要',
  workWithUs: '私たちと働く',
  friendsReferral: '友人紹介',
  testimonials: 'お客様の声',
  successCases: '成功事例',

  // Illustrations
  illustrations: 'イラスト',
  brain: '脳',
  cell: 'セル',
  rna: 'RNA',
  mouse: 'マウス',
  illustrationsSeeAll: 'すべて見る',

  // Templates
  templates: 'テンプレート',
  slidesPresentations: (
    <>
      スライド
      <br />
      プゼンテーション
    </>
  ),
  graphicalAbstracts: (
    <>
      グラフィカル
      <br /> アブストラクト
    </>
  ),
  infographics: (
    <>
      インフォグラ
      <br />
      フィック
    </>
  ),
  posters: 'ポスター',
  templatesSeeAll: 'すべて見る',

  // Subscriptions
  subscriptions: 'サブスクリプション',
  starter: 'スターター',
  student: '学生',
  researcher: '研究員',
  institutions: '機関',

  // Resources
  resources: 'リソース',
  posterMaker: 'ポスターメーカー',
  customDesigns: 'カスタムデザイン',
  helpCenter: 'ヘルプセンター',
  termsOfUse: '個人情報保護方針',
  privacyPolicy: 'クッキーポリシー',
  cookiesPolicy: 'ご利用規約',

  // Content
  content: '内容',
  scientificIllustration: '科学的イラストレーション 視覚科学の世界への鍵 ',
  researcherPaper: '研究論文の図表の入れ方',
  effectiveGraphical: '効果的なグラフィカル・アブストラクトの作り方',
  contentSeeAll: 'すべて見る',

  connectSocialMedia: 'ソーシャルメディアでつながる',
  copyright: 'Copyright',
  mtgAllRightsReserved: 'Cactus Communications. All rights reserved',
  careers: 'キャリア',
};
