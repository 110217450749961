import React from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';
import Image from 'next/image';
import LazyLoad from 'react-lazyload';
import { Box, Container, Link, Typography } from '@mui/material';
import { useStyles } from './styles';
import { useRouter } from 'next/router';
import { setLocale } from 'utils/locale';

import en from './locales/en';
import es from './locales/es';
import fr from './locales/fr';
import it from './locales/it';
import ja from './locales/jp';
import zh from './locales/zh';
import ko from './locales/ko';
import { useAuthUser } from 'features/auth/AuthUserContext';

const Props = {
  socialLinks: PropTypes.bool,
  linkPagesInfo: PropTypes.bool,
  localeProp: PropTypes.string,
};

const DefaultProps = {
  socialLinks: true,
  linkPagesInfo: true,
  localeProp: '',
};

export default function PageFooter({ socialLinks, linkPagesInfo, localeProp }) {
  const classes = useStyles();
  const { user } = useAuthUser();
  const router = useRouter();
  const { locale } = router;
  const isIllustrationsPage = router.asPath.includes('/illustrations');
  const supportedLocales = { en, es, fr, it, ja, ko, zh };
  const currentLocale = localeProp && supportedLocales[localeProp] ? localeProp : locale;
  const t = isIllustrationsPage
    ? setLocale(locale, supportedLocales)
    : setLocale(currentLocale, supportedLocales);

  return (
    <LazyLoad offset={100}>
      <div>
        {linkPagesInfo && (
          <>
            <div className={classes.fatherFooter}>
              <div className={classes.newFooter}>
                <div className={classes.logo}>
                  <Link href="/" underline="hover">
                    <Image
                      src="/images/mtg-logo.svg"
                      alt="Mind The Graph Logo"
                      loading="lazy"
                      width={130}
                      height={43}
                    />
                  </Link>
                </div>

                <div className={classes.listTags}>
                  <p className={classes.titleList}>Mind The Graph</p>
                  <NextLink href="/about-us" passHref={true}>
                    <Link underline="hover">{t.aboutUs}</Link>
                  </NextLink>
                  <NextLink href="/friends-referral" passHref={true}>
                    <Link underline="hover">{t.friendsReferral}</Link>
                  </NextLink>
                  <NextLink href="/feedbacks" passHref={true}>
                    <Link underline="hover">{t.testimonials}</Link>
                  </NextLink>
                  <NextLink href="/success-cases" passHref={true}>
                    <Link underline="hover">{t.successCases}</Link>
                  </NextLink>
                </div>

                <div className={classes.listTags}>
                  <p className={classes.titleList}>{t.illustrations}</p>
                  <NextLink href="/illustrations/blood-brain-barrier" passHref={true}>
                    <Link underline="hover">{t.brain}</Link>
                  </NextLink>
                  <NextLink href="/illustrations/stem-cell" passHref={true}>
                    <Link underline="hover">{t.cell}</Link>
                  </NextLink>
                  <NextLink href="/illustrations/rna" passHref={true}>
                    <Link underline="hover">{t.rna}</Link>
                  </NextLink>
                  <NextLink
                    href="/illustrations/mouse-body-condition-score-emaciated"
                    passHref={true}
                  >
                    <Link underline="hover">{t.mouse}</Link>
                  </NextLink>
                  <NextLink href="/illustrations" passHref={true}>
                    <Link underline="hover">{t.illustrationsSeeAll}</Link>
                  </NextLink>
                </div>

                <div className={classes.listTags}>
                  <p className={classes.titleList}>{t.templates}</p>
                  <NextLink href="/infographic-templates/format/slides" passHref={true}>
                    <Link underline="hover">{t.slidesPresentations}</Link>
                  </NextLink>
                  <NextLink href="/infographic-templates/format/abstracts" passHref={true}>
                    <Link underline="hover">{t.graphicalAbstracts}</Link>
                  </NextLink>
                  <NextLink href="/infographic-templates/format/infographics" passHref={true}>
                    <Link underline="hover">{t.infographics}</Link>
                  </NextLink>
                  <NextLink href="/infographic-templates/format/posters" passHref={true}>
                    <Link underline="hover">{t.posters}</Link>
                  </NextLink>
                  <NextLink href="/infographic-templates" passHref={true}>
                    <Link underline="hover">{t.templatesSeeAll}</Link>
                  </NextLink>
                </div>

                <div className={classes.listTags}>
                  <p className={classes.titleList}>{t.subscriptions}</p>
                  <NextLink
                    href={user ? '/my-account/overview' : '/auth/login-register'}
                    passHref={true}
                  >
                    <Link underline="hover">{t.starter}</Link>
                  </NextLink>
                  <NextLink href="/pricing" passHref={true}>
                    <Link underline="hover">{t.researcher}</Link>
                  </NextLink>
                  <NextLink href="/institutions" passHref={true}>
                    <Link underline="hover">{t.institutions}</Link>
                  </NextLink>
                </div>

                <div className={classes.listTags}>
                  <p className={classes.titleList}>{t.resources}</p>
                  <NextLink href="/poster-maker" passHref={true}>
                    <Link underline="hover">{t.posterMaker}</Link>
                  </NextLink>
                  <NextLink href="/custom-services" passHref={true}>
                    <Link underline="hover">{t.customDesigns}</Link>
                  </NextLink>
                  <NextLink href="/blog/faq" passHref={true}>
                    <Link underline="hover">{t.helpCenter}</Link>
                  </NextLink>
                </div>

                <div className={classes.listTags}>
                  <p className={classes.titleList}>{t.content}</p>
                  <NextLink href="/blog/scientific-illustration-2" passHref={true}>
                    <Link underline="hover">{t.scientificIllustration}</Link>
                  </NextLink>
                  <NextLink href="/blog/how-to-include-figures-in-a-research-paper" passHref={true}>
                    <Link underline="hover">{t.researcherPaper}</Link>
                  </NextLink>
                  <NextLink href="/blog/create-effective-graphical-abstract" passHref={true}>
                    <Link underline="hover">{t.effectiveGraphical}</Link>
                  </NextLink>
                  <NextLink href="/blog" passHref={true}>
                    <Link underline="hover">{t.contentSeeAll}</Link>
                  </NextLink>
                </div>
              </div>
            </div>

            <div className={classes.container}>
              {socialLinks && (
                <>
                  <Typography className={classes.connectSocialMediText}>
                    {t.connectSocialMedia}
                  </Typography>

                  <div>
                    <Link
                      className={classes.socialLink}
                      href="https://facebook.com/mindthegraph"
                      target="_blank"
                      underline="hover"
                    >
                      <Image
                        src="/images/footer-social-icons/facebook.svg"
                        alt="Mind the Graph Facebook"
                        loading="lazy"
                        width={24}
                        height={24}
                      />
                    </Link>

                    <Link
                      className={classes.socialLink}
                      href="https://instagram.com/mindthegraph"
                      target="_blank"
                      underline="hover"
                    >
                      <Image
                        src="/images/footer-social-icons/instagram.svg"
                        alt="Mind the Graph Instagram"
                        loading="lazy"
                        width={24}
                        height={24}
                      />
                    </Link>

                    <Link
                      className={classes.socialLink}
                      href="https://linkedin.com/company/mind-the-graph/"
                      target="_blank"
                      underline="hover"
                    >
                      <Image
                        src="/images/footer-social-icons/linkedin.svg"
                        alt="Mind the Graph Linkedin"
                        loading="lazy"
                        width={24}
                        height={24}
                      />
                    </Link>

                    <Link
                      className={classes.socialLink}
                      href="https://mindthegraph.com/blog/"
                      target="_blank"
                      underline="hover"
                    >
                      <Image
                        src="/images/footer-social-icons/blogger.svg"
                        alt="Mind The Graph Blog"
                        loading="lazy"
                        width={24}
                        height={24}
                      />
                    </Link>

                    <Link
                      className={classes.socialLink}
                      href="https://twitter.com/mindthegraph"
                      target="_blank"
                      underline="hover"
                    >
                      <Image
                        src="/images/footer-social-icons/x.svg"
                        alt="Mind The Graph Twitter"
                        loading="lazy"
                        width={24}
                        height={24}
                      />
                    </Link>

                    <Link
                      className={classes.socialLink}
                      href="https://www.youtube.com/c/MindtheGraph"
                      target="_blank"
                      underline="hover"
                    >
                      <Image
                        src="/images/footer-social-icons/youtube.svg"
                        alt="Mind The Graph YouTube"
                        loading="lazy"
                        width={24}
                        height={24}
                      />
                    </Link>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        <Box bgcolor="#1F2937" style={{ borderTop: '1px solid #fff' }}>
          <Container>
            <footer className={classes.containerBar}>
              <div>
                <Link href="https://cactusglobal.com/" target="_blank" underline="hover">
                  <Image
                    className={classes.secondaryBrandLogo}
                    src="/images/cactus-logo-white.svg"
                    alt="Cactus Communications Logo"
                    loading="lazy"
                    width={190}
                    height={35}
                  />
                </Link>

                <Typography className={classes.copyrightText}>
                  {t.copyright} {new Date().getFullYear()} {t.mtgAllRightsReserved}.
                </Typography>
              </div>

              <Box className={classes.barLinks}>
                <NextLink href="https://cactusglobal.com/terms-of-use/" passHref={true}>
                  <Link target="_blank" underline="hover">
                    {t.termsOfUse}
                  </Link>
                </NextLink>
                <NextLink href="https://cactusglobal.com/privacy-policy/" passHref={true}>
                  <Link target="_blank" underline="hover">
                    {t.privacyPolicy}
                  </Link>
                </NextLink>
                <NextLink href="https://cactusglobal.com/cookies/" passHref={true}>
                  <Link target="_blank" underline="hover">
                    {t.cookiesPolicy}
                  </Link>
                </NextLink>
                <NextLink href="https://cactusglobal.com/careers/" passHref={true}>
                  <Link target="_blank" underline="hover">
                    {t.careers}
                  </Link>
                </NextLink>
              </Box>
            </footer>
          </Container>
        </Box>
      </div>
    </LazyLoad>
  );
}

PageFooter.propTypes = Props;
PageFooter.defaultProps = DefaultProps;
