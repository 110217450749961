export default {
  aboutUs: 'Chi Siamo',
  workWithUs: 'Lavora Con Noi',
  friendsReferral: 'Referimento di Amici',
  testimonials: 'Testimonianze',
  successCases: 'Casi di Successo',

  // Illustrations
  illustrations: 'Illustrazioni',
  brain: 'Cervello',
  cell: 'Cellula',
  rna: 'RNA',
  mouse: 'Topo',
  illustrationsSeeAll: 'Vedi tutto',

  // Templates
  templates: 'Modelli',
  slidesPresentations: 'Presentazioni di Diapositive',
  graphicalAbstracts: 'Abstract Grafici',
  infographics: 'Infografiche',
  posters: 'Poster',
  templatesSeeAll: 'Vedi tutto',

  // Subscriptions
  subscriptions: 'Abbonamenti',
  starter: 'Inizio',
  student: 'Studente',
  researcher: 'Ricercatore',
  institutions: 'Istituzioni',

  // Resources
  resources: 'Risorse',
  posterMaker: 'Creatore di Poster',
  customDesigns: 'Design Personalizzati',
  helpCenter: 'Centro di Aiuto',
  termsOfUse: 'Termini di Utilizzo',
  privacyPolicy: 'Informativa sulla Privacy',
  cookiesPolicy: 'Informativa sui Cookie',

  // Content
  content: 'Contenuti',
  scientificIllustration: 'Illustrazione Scientifica: La Chiave per un Mondo di Scienza Visiva',
  researcherPaper: 'Come Includere Figure in un Articolo di Ricerca',
  effectiveGraphical: 'Come Creare un Abstract Grafico Efficace',
  contentSeeAll: 'Vedi tutto',

  connectSocialMedia: 'Possiamo risolvere il problema al più presto?',
  copyright: 'Copyright',
  mtgAllRightsReserved: 'Cactus Communications. All rights reserved',
  careers: 'Carriera',
};
