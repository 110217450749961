import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2),
    backgroundColor: '#212121',
    textAlign: 'center',
    fontSize: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3C3D41',
    paddingBottom: theme.spacing(4),

    '&:last-child': {
      marginTop: theme.spacing(2),
    },
  },
  connectSocialMediText: {
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 'normal',
    paddingRight: theme.spacing(4),
  },
  secondaryBrandLogo: {
    width: 190,
    height: 43.27,
    marginTop: theme.spacing(1),
  },
  socialLink: {
    display: 'inline-block',

    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  copyrightText: {
    color: theme.palette.common.white,
    fontSize: 14,
    marginLeft: theme.spacing(0.7),
    paddingTop: theme.spacing(2.5),
  },
  barLinks: {
    fontSize: 14,
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'end',
    gridGap: 20,

    '& a': {
      color: theme.palette.common.white,
    },
  },
  newFooter: {
    width: '100%',
    background: '#3C3D41',
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    padding: '0px 30px 20px 30px',
    maxWidth: 1300,
  },
  listTags: {
    display: 'grid',
    color: '#FFFFFF',
    alignContent: 'start',
    '& a': {
      color: '#E0E0E0',
      padding: '5px 0',
    },
  },
  logo: {
    paddingTop: 27,
  },
  titleList: {
    paddingTop: 11,
    marginBottom: 7,
    fontWeight: 'bold',
    fontSize: 18,
  },
  fatherFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#3C3D41',
  },
  containerBar: {
    backgroundColor: '#1F2937',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 0),
  },
}));
