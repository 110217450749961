export default {
  aboutUs: '회사 소개',
  workWithUs: '우리와 함께 일하기',
  friendsReferral: '친구 추천',
  testimonials: '사용 후기',
  successCases: '성공 사례',

  // Illustrations
  illustrations: '일러스트레이션',
  brain: '뇌',
  cell: '셀',
  rna: 'RNA',
  mouse: '마우스',
  illustrationsSeeAll: '모두 보기',

  // Templates
  templates: '템플릿',
  slidesPresentations: '슬라이드 프레젠테이션',
  graphicalAbstracts: '그래픽 초록',
  infographics: '인포그래픽',
  posters: '포스터',
  templatesSeeAll: '모두 보기',

  // Subscriptions
  subscriptions: '구독',
  starter: '스타터',
  student: '학생',
  researcher: '연구원',
  institutions: '교육기관',

  // Resources
  resources: '리소스',
  posterMaker: '포스터 메이커',
  customDesigns: '맞춤형 디자인',
  helpCenter: '도움말 센터',
  termsOfUse: '개인정보처리방침',
  privacyPolicy: '쿠키정책',
  cookiesPolicy: '이용약관',

  // Content
  content: '콘텐츠',
  scientificIllustration: '과학적 일러스트레이션: 시각 과학의 세계로 가는 열쇠 ',
  researcherPaper: '연구 논문에 그림을 포함하는 방법',
  effectiveGraphical: '효과적인 그래픽 초록을 만드는 방법',
  contentSeeAll: '모두 보기',

  connectSocialMedia: '소셜 미디어에서 연결',
  copyright: 'Copyright',
  mtgAllRightsReserved: 'Cactus Communications. All rights reserved',
  careers: '채용',
};
