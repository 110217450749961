export default {
  aboutUs: 'Sobre Nosotros',
  workWithUs: 'Trabaja Con Nosotros',
  friendsReferral: 'Referir a Amigos',
  testimonials: 'Testimonios',
  successCases: 'Casos de Éxito',

  // Illustrations
  illustrations: 'Ilustraciones',
  brain: 'Cerebro',
  cell: 'Célula',
  rna: 'ARN',
  mouse: 'Ratón',
  illustrationsSeeAll: 'Ver todo',

  // Templates
  templates: 'Plantillas',
  slidesPresentations: 'Presentaciones de Diapositivas',
  graphicalAbstracts: 'Resúmenes Gráficos',
  infographics: 'Infografía',
  posters: 'Pósteres',
  templatesSeeAll: 'Ver todo',

  // Subscriptions
  subscriptions: 'Suscripciones',
  starter: 'Inicio',
  student: 'Estudiante',
  researcher: 'Investigador',
  institutions: 'Instituciones',

  // Resources
  resources: 'Recursos',
  posterMaker: 'Creador de Pósteres',
  customDesigns: 'Diseños Personalizados',
  helpCenter: 'Centro de Ayuda',
  termsOfUse: 'Condiciones de Uso',
  privacyPolicy: 'Política de Privacidad',
  cookiesPolicy: 'Política de Cookies',

  // Content
  content: 'Contenido',
  scientificIllustration: 'Ilustración Científica: La Clave para un Mundo de Ciencia Visual',
  researcherPaper: 'Cómo Incluir Figuras en un Artículo de Investigación',
  effectiveGraphical: 'Cómo Crear un Resumen Gráfico Efectivo',
  contentSeeAll: 'Ver todo',

  connectSocialMedia: 'Conéctate en las redes sociales',
  copyright: 'Copyright',
  mtgAllRightsReserved: 'Cactus Communications. All rights reserved',
  careers: 'Oportunidades laborales',
};
