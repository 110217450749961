export default {
  aboutUs: 'À propos de nous',
  workWithUs: 'Travailler avec nous',
  friendsReferral: "Référence d'amis",
  testimonials: 'Témoignages',
  successCases: 'Cas de succès',

  // Illustrations
  illustrations: 'Illustrations',
  brain: 'Cerveau',
  cell: 'Cellule',
  rna: 'ARN',
  mouse: 'Souris',
  illustrationsSeeAll: 'Voir tout',

  // Templates
  templates: 'Modèles',
  slidesPresentations: 'Présentations de Diapositives',
  graphicalAbstracts: 'Résumés Graphiques',
  infographics: 'Infographie',
  posters: 'Affiches',
  templatesSeeAll: 'Voir tout',

  // Subscriptions
  subscriptions: 'Abonnements',
  starter: 'Débutant',
  student: 'Étudiant',
  researcher: 'Chercheur',
  institutions: 'Institutions',

  // Resources
  resources: 'Ressources',
  posterMaker: "Créateur d'Affiches",
  customDesigns: 'Conceptions Personnalisées',
  helpCenter: "Centre d'Aide",
  termsOfUse: "Conditions d'Utilisation",
  privacyPolicy: 'Politique de Confidentialité',
  cookiesPolicy: 'Politique de Cookies',

  // Content
  content: 'Contenu',
  scientificIllustration: "Illustration Scientifique : La Clé d'un Monde de Science Visuelle",
  researcherPaper: 'Comment Inclure des Figures dans un Article de Recherche',
  effectiveGraphical: 'Comment Créer un Résumé Graphique Efficace',
  contentSeeAll: 'Voir tout',

  connectSocialMedia: 'Pouvons-nous résoudre le problème le plus rapidement possible ?',
  copyright: 'Copyright',
  mtgAllRightsReserved: 'Cactus Communications. All rights reserved',
  careers: 'Carrières',
};
