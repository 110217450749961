export default {
  aboutUs: 'About Us',
  workWithUs: 'Work With Us',
  friendsReferral: 'Friends Referral',
  testimonials: 'Testimonials',
  successCases: 'Success Cases',

  // Illustrations
  illustrations: 'Illustrations',
  brain: 'Brain',
  cell: 'Cell',
  rna: 'RNA',
  mouse: 'Mouse',
  illustrationsSeeAll: 'See all',

  // Templates
  templates: 'Templates',
  slidesPresentations: 'Slides Presentations',
  graphicalAbstracts: 'Graphical Abstracts',
  infographics: 'Infographics',
  posters: 'Posters',
  templatesSeeAll: 'See all',

  // Subscriptions
  subscriptions: 'Subscriptions',
  starter: 'Starter',
  student: 'Student',
  researcher: 'Researcher',
  institutions: 'Institutions',

  // Resources
  resources: 'Resources',
  posterMaker: 'Poster Maker',
  customDesigns: 'Custom Designs',
  helpCenter: 'Help Center',
  termsOfUse: 'Terms of Use',
  privacyPolicy: 'Privacy Policy',
  cookiesPolicy: 'Cookies Policy',

  // Content
  content: 'Content',
  scientificIllustration: 'Scientific illustration: The key to a world of visual science',
  researcherPaper: 'How to Include Figures in a Research Paper',
  effectiveGraphical: 'How to Create an Effective Graphical Abstract',
  contentSeeAll: 'See all',

  connectSocialMedia: 'Connect on Social Media',
  copyright: 'Copyright',
  mtgAllRightsReserved: 'Cactus Communications. All rights reserved',
  careers: 'Careers',
};
